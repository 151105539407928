<template>
  <div class="music-detail-container">
    <div class="music-header-wrap">
      <img :src="musicInfo.logo" alt="">
      <div class="des">
        <span>{{musicInfo.name}}</span>
        <p>{{musicInfo.desc}}</p>
        <!-- <div class="lables">
          <span>现代交响</span>
          <span>感性/抒情</span>
        </div> -->
      </div>
      <div class="play" @click="handlePlayAll">播放全部</div>
    </div>
    <MusicList
      :showOrder=false
      :data.sync="musicList"
    />
  </div>
</template>
<script>
import { playlistMusicDetail } from "@/api/music";
import MusicList from "@/components/MusicList";
import Buttons from "@/layout/mixins/Buttons";

export default {
  name: "ListDetail",
  components: {
    MusicList
  },
  mixins: [Buttons],

  data(){
    return {
      musicList: [],
      musicInfo: {}
    }
  },
  created(){
    this.getSongDetail(this.$route.params.id)
  },
  methods: {
    async getSongDetail(playlist_id){
      try {
          const { data: {musiclist, playlist} } = await playlistMusicDetail({playlist_id});
          this.musicList = musiclist;
          this.musicInfo = playlist
        } catch (e) {
          console.log(e);
        }
    },
    async handlePlayAll() {
        this.$store.dispatch("music/updateOrderList", this.musicList);
        await this.handlePlayerAll(this.musicList[0], 2);
    }
  }
}
</script>

<style lang="scss" scoped>
$max-width: 1200px;
.center {
  width: $max-width;
  margin: 0 auto;
}
.music-detail-container {
  @extend .center;
  margin-top: 40px;
  padding-top: 70px;
  padding-bottom: 80px;
  min-height: calc(100vh - 268px);
}
.music-header-wrap {
  position: relative;
  @extend .center;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  padding:30px 40px;
  display: flex;
  img{
    width: 160px;
    height: 160px;
    border-radius: 10px;
  }
  .des {
    margin-left: 24px;
    color: #7A7E83;
    font-size: 14px;
    span {
      font-weight: bold;
      color: #333B45;
      font-size: 24px;
      margin: 12px 0 16px 0;
    }
    .lables {
      span{
        padding: 5px 16px;
        color: #333333;
        font-size: 12px;
        border: 1px solid #999DA2;
        border-radius: 4px;
        margin-right: 6px;
      }
    }
  }
  .play {
    cursor: pointer;
    position: absolute;
    right: 40px;
    bottom: 30px;
    font-family: PingFangSC-Semibold, PingFang SC;
    width: 136px;
    height: 40px;
    border-radius: 6px;
    background: #E52A0D;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 600;
    line-height: 40px;
    text-align: center;
  }
}


</style>
